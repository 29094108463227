import React, { useState } from "react";
import { Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import {
  FcHome,
  FcBusinessman,
  FcViewDetails,
  FcDepartment,
  FcCircuit,
  FcPortraitMode,
  FcAssistant,
  FcFile,
  FcElectronics,
  FcTodoList,
  FcRatings,
  FcGenericSortingDesc,
  FcConferenceCall,
} from "react-icons/fc";
import { RiCommunityLine } from "react-icons/ri";
import { RxFileText } from "react-icons/rx";
import { NavLink } from "react-router-dom";

const getMenuItems = (user, currentLocation) => {
  const items = [
    {
      condition: true,
      icon: <FcHome />,
      label: "Inicio",
      to: "/",
      active: currentLocation === "/",
    },
    {
      condition: [
        "Departamento Gerencial",
        "Administrativo",
        "Departamento Sistemas",
      ].includes(user.departmentName),
      icon: <FcBusinessman />,
      label: "Usuarios",
      to: "user",
      active: currentLocation === "/user",
    },
    {
      condition:
        [
          "Departamento Gerencial",
          "Departamento Sistemas",
          "Departamento Administrativo",
          "GERENTE SOPORTE TECNICO",
        ].includes(user.departmentName) ||
        user.jobTitle === "GERENTE SOPORTE TECNICO",
      icon: <FcViewDetails />,
      label: "Datos Boleta",
      subMenu: [
        {
          icon: <FcDepartment />,
          label: "Entidades Financieras",
          to: "company",
          active: currentLocation === "/company",
        },
        {
          icon: <RiCommunityLine />,
          label: "Agencias",
          to: "agency",
          active: currentLocation === "/agency",
        },
        {
          icon: <FcCircuit />,
          label: "Equipos",
          to: "equipment",
          active: currentLocation === "/equipment",
        },
        {
          icon: <FcPortraitMode />,
          label: "Contactos",
          to: "contact",
          active: currentLocation === "/contact",
        },
        {
          icon: <FcAssistant />,
          label: "Proveedores",
          to: "provider",
          active: currentLocation === "/provider",
        },
      ],
    },
    {
      condition: user.departmentName === "Departamento Cliente",
      icon: <FcFile />,
      label: "Boletas",
      to: "ticketForClient",
      active: currentLocation === "/ticketForClient",
    },
    {
      condition: user.departmentName !== "Departamento Cliente",
      icon: <FcViewDetails />,
      label: "Boletas",
      subMenu: [
        {
          icon: <FcFile />,
          label: "Solicitudes",
          to: "ticketRequest",
          active: currentLocation === "/ticketRequest",
        },
        {
          icon: <FcFile />,
          label: "Capacitación",
          to: "ticketTraining",
          active: currentLocation === "/ticketTraining",
        },
        {
          icon: <FcFile />,
          label: "Boletas x Entidades",
          to: "ticketsForCompany",
          active: currentLocation === "/ticketsForCompany",
        },
        {
          icon: <FcFile />,
          label: "Boletas Generales",
          to: "tickets",
          active: currentLocation === "/tickets",
        },
      ],
    },
    {
      condition:
        [
          "Departamento Gerencial",
          "Departamento Sistemas",
          "Departamento Administrativo",
          "GERENTE SOPORTE TECNICO",
        ].includes(user.departmentName) ||
        user.jobTitle === "GERENTE SOPORTE TECNICO",
      icon: <FcElectronics />,
      label: "Inventario",
      subMenu: [
        {
          icon: <RxFileText />,
          label: "Lista de Activos",
          to: "assetList",
          active: currentLocation === "/assetList",
        },
        {
          icon: <RxFileText />,
          label: "Datos Inventario",
          subMenu: [
            {
              icon: <RxFileText />,
              label: "Inventario",
              to: "inventory",
              active: currentLocation === "/inventory",
            },
            {
              icon: <RxFileText />,
              label: "Entrada x Prod.",
              to: "productsEntry",
              active: currentLocation === "/productsEntry",
            },
            {
              icon: <RxFileText />,
              label: "Salida Prod. Boleta",
              to: "outputProduct",
              active: currentLocation === "/outputProduct",
            },
            {
              icon: <RxFileText />,
              label: "Stock Por Prod.",
              to: "stockProduct",
              active: currentLocation === "/stockProduct",
            },
          ],
        },
      ],
    },
    {
      condition:
        [
          "Departamento Gerencial",
          "Departamento Sistemas",
          "Departamento Administrativo",
          "GERENTE SOPORTE TECNICO",
        ].includes(user.departmentName) ||
        user.jobTitle === "GERENTE SOPORTE TECNICO",
      icon: <FcRatings />,
      label: "Reportes de boletas",
      subMenu: [
        {
          icon: <FcTodoList />,
          label: "Cotizacion x Boleta",
          to: "productxticket",
          active: currentLocation === "/productxticket",
        },
        {
          icon: <FcTodoList />,
          label: "Conciliacion Departamento",
          to: "depReportTickets",
          active: currentLocation === "/depReportTickets",
        },
        {
          icon: <FcTodoList />,
          label: "Conciliacion General",
          to: "generalReportTickets",
          active: currentLocation === "/generalReportTickets",
        },
      ],
    },
    {
      condition:
        [
          "Departamento Gerencial",
          "Departamento Sistemas",
          "Departamento Administrativo",
          "GERENTE SOPORTE TECNICO",
        ].includes(user.departmentName) ||
        user.jobTitle === "GERENTE SOPORTE TECNICO",
      icon: <FcRatings />,
      label: "Reportes de repuestos",
      subMenu: [
        {
          icon: <FcTodoList />,
          label: "Datos historico equipo",
          to: "infoEquipment",
          active: currentLocation === "/infoEquipment",
        },
        {
          icon: <FcTodoList />,
          label: "Precios Repuesto",
          to: "reportproductPrices",
          active: currentLocation === "/reportproductPrices",
        },
        {
          icon: <FcTodoList />,
          label: "Rep. x Dep. Repuestos",
          to: "depReportProduct",
          active: currentLocation === "/depReportProduct",
        },
        {
          icon: <FcTodoList />,
          label: "Rep. General Repuestos",
          to: "generalReportProduct",
          active: currentLocation === "/generalReportProduct",
        },
        {
          icon: <FcTodoList />,
          label: "Repuestos",
          to: "totalProduct",
          active: currentLocation === "/totalProduct",
        },
      ],
    },
    {
      condition:
        [
          "Departamento Gerencial",
          "Departamento Sistemas",
          "Departamento Administrativo",
          "GERENTE SOPORTE TECNICO",
        ].includes(user.departmentName) ||
        user.jobTitle === "GERENTE SOPORTE TECNICO",
      icon: <FcRatings />,
      label: "Reportes estadisticos",
      subMenu: [
        {
          icon: <FcTodoList />,
          label: "Cant. Repuestos",
          to: "countProducts",
          active: currentLocation === "/countProducts",
        },
        {
          icon: <FcTodoList />,
          label: "Cuestionario",
          to: "reportStatistics",
          active: currentLocation === "/reportStatistics",
        },
      ],
    },
    {
      condition: user.departmentName === "Departamento Cliente" && !user.city,
      icon: <FcRatings />,
      label: "Reportes de boletas",
      subMenu: [
        {
          icon: <FcTodoList />,
          label: "Cantidad de boletas",
          to: "listTicketClient",
          active: currentLocation === "/listTicketClient",
        },
        {
          icon: <FcTodoList />,
          label: "Cantidad de boletas Dep.",
          to: "listTicketClientDep",
          active: currentLocation === "/listTicketClientDep",
        },
      ],
    },
    {
      condition: user.departmentName === "Departamento Cliente" && user.city,
      icon: <FcRatings />,
      label: "Reportes de boletas",
      subMenu: [
        {
          icon: <FcTodoList />,
          label: "Cantidad de boletas Dep.",
          to: "listTicketClientDep",
          active: currentLocation === "/listTicketClientDep",
        },
      ],
    },

    {
      condition: user.departmentName === "Departamento Cliente" && !user.city,
      icon: <FcRatings />,
      label: "Reportes de repuestos",
      subMenu: [
        {
          icon: <FcTodoList />,
          label: "Cant. de repuestos.",
          to: "listProductClient",
          active: currentLocation === "/listProductClient",
        },
        {
          icon: <FcTodoList />,
          label: "Cant. de repuestos Dep.",
          to: "listProductClientDep",
          active: currentLocation === "/listProductClientDep",
        },
      ],
    },
    {
      condition: user.departmentName === "Departamento Cliente" && user.city,
      icon: <FcRatings />,
      label: "Reportes de repuestos",
      subMenu: [
        {
          icon: <FcTodoList />,
          label: "Cant. de repuestos.",
          to: "listProductClient",
          active: currentLocation === "/listProductClient",
        },
        {
          icon: <FcTodoList />,
          label: "Cant. de repuestos Dep.",
          to: "listProductClientDep",
          active: currentLocation === "/listProductClientDep",
        },
      ],
    },
    {
      condition: user.jobTitle === "COORDINADOR",
      icon: <FcRatings />,
      label: "Reporte",
      subMenu: [
        {
          icon: <FcTodoList />,
          label: "Datos historico equipo",
          to: "infoEquipment",
          active: currentLocation === "/infoEquipment",
        },
        {
          icon: <FcTodoList />,
          label: "Repuestos",
          to: "totalProduct",
          active: currentLocation === "/totalProduct",
        },
      ],
    },
    {
      condition: true,
      icon: <FcGenericSortingDesc />,
      label: "Varios",
      subMenu: [
        {
          icon: <FcConferenceCall />,
          label: "Staff",
          to: "staff",
          active: currentLocation === "/staff",
        },
      ],
    },
  ];

  return items.filter((item) => item.condition);
};

export const CustomSidebarContent = ({ user, currentLocation }) => {
  const [openSubMenu, setOpenSubMenu] = useState("");
  const [openNestedSubMenu, setOpenNestedSubMenu] = useState("");

  const handleSubMenuClick = (label) => {
    setOpenSubMenu(openSubMenu === label ? "" : label);
  };

  const handleNestedSubMenuClick = (label) => {
    setOpenNestedSubMenu(openNestedSubMenu === label ? "" : label);
  };

  const menuItems = getMenuItems(user, currentLocation);

  return (
    <Menu>
      {menuItems.map((item, index) =>
        item.subMenu ? (
          <SubMenu
            key={index}
            icon={item.icon}
            title={item.label}
            open={openSubMenu === item.label}
            onOpenChange={() => handleSubMenuClick(item.label)}
          >
            {item.subMenu.map((subItem, subIndex) =>
              subItem.subMenu ? (
                <SubMenu
                  key={subIndex}
                  icon={subItem.icon}
                  title={subItem.label}
                  open={openNestedSubMenu === subItem.label}
                  onOpenChange={() => handleNestedSubMenuClick(subItem.label)}
                >
                  {subItem.subMenu.map((nestedSubItem, nestedSubIndex) => (
                    <MenuItem
                      key={nestedSubIndex}
                      icon={nestedSubItem.icon}
                      className={nestedSubItem.active ? "active" : ""}
                    >
                      <NavLink to={nestedSubItem.to}>
                        {nestedSubItem.label}
                      </NavLink>
                    </MenuItem>
                  ))}
                </SubMenu>
              ) : (
                <MenuItem
                  key={subIndex}
                  icon={subItem.icon}
                  className={subItem.active ? "active" : ""}
                >
                  <NavLink to={subItem.to}>{subItem.label}</NavLink>
                </MenuItem>
              )
            )}
          </SubMenu>
        ) : (
          <MenuItem
            key={index}
            icon={item.icon}
            className={item.active ? "active" : ""}
          >
            <NavLink to={item.to}>{item.label}</NavLink>
          </MenuItem>
        )
      )}
    </Menu>
  );
};
